import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const MinskMazowieckiCmentarzParafialny = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Cmentarz Parafialny w Mińsku Mazowieckim" />
      <ReturnToPrevPage to={'/gminy/minsk-mazowiecki'}>
        Materiały dotyczące miasta Mińsk Mazowiecki oraz otaczającej go gminy
      </ReturnToPrevPage>
      <Header1>Cmentarz Parafialny w Mińsku Mazowieckim</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <VideoPlayer videoId={895166191} />
        <p>
          Pierwotne miejsce pochówku osób zmarłych w mieście nad Srebrną
          znajdowało się przy kościele parafialnym, obecnie posiadającym
          wezwanie Narodzenia Najświętszej Maryi Panny. Tymczasem modernizacyjne
          prace prowadzone u zbiegu ulic Kościelnej i Budowlanej doprowadziły
          także do ujawnienia innego obszaru na terenie Mińska Mazowieckiego,
          gdzie spoczywają nieznane nam obecnie osoby. Prawdopodobnie zostały
          pochowane tam w XVII wieku. Zgodnie z tym co napisano wcześniej
          ostatnie dekady następnego wieku przyniosły wyznaczenie nowego terenu
          pod lokalizację mińskomazowieckiego cmentarza. Powodów tego stanu
          rzeczy można upatrywać w dwóch przyczynach. Pierwszą z nich były
          względy sanitarne. – W pewnym momencie zorientowano się bowiem, iż
          istnieje silny związek pomiędzy ciałami zmarłych a zarazami, które
          szalały w Polce aż po XVIII, a nawet XIX w. – podkreśla historyk
          Damian Sitkiewicz. Założenie cmentarza podyktowane było również
          kurczącym się miejscem pochówku znajdującym się przy kościele.
          Początkowo nekropolia obejmowała około półtorahektarowy teren
          znajdujący się po obu stronach obecnej alei głównej. Rozwój tego
          obszaru następował w kierunku północnym, zaś w okresie powojennym we
          wschodnim. Obecne poszerzanie jego powierzchni polega na
          uporządkowaniu pasa ziemi położonego tuż przy drodze dojazdowej do
          cmentarza komunalnego.
        </p>
        <p>
          Warto zauważyć, że na mińskomazowieckim miejscu wiecznego spoczynku
          można było wyróżnić, poza rzymskokatolicką, części prawosławną i
          mariawicką. Współcześnie ich dawne granice uległy zatarciu. Na
          opisywanym miejscu przetrwało kilka nagrobków osób wyznania
          prawosławnego. Wśród pochowanych tu osób prawdopodobnie przybyłych z
          organicznej części Rosji uwagę przede wszystkim zwraca czarny,
          granitowy, pomnik rotmistrza Lwa Romanowa. Służył on w stacjonującym
          przed pierwszą wojną światową w Mińsku Mazowieckim (wtedy Nowomińsku)
          13 Pułku Ułanów Włodzimierskich. Najstarszym, obecnie, nagrobkiem
          znajdującym się na cmentarzu jest kamienna mogiła Władysława Ruloffa.
          Pochodzi ona z grudnia 1836 roku. O dziewięć lat młodszy jest okazały
          pomnik nagrobny hrabiego Bartłomieja Lanckorońskiego. Późniejszy
          właściciel majątku w Mistowie uczestniczył w powstaniach
          kościuszkowskim i listopadowym oraz w kampaniach napoleońskich. Za
          zasługi w bitwie pod Olszynką Grochowską został odznaczony Krzyżem
          Virtuti Militari. Co ciekawe ten zabytkowy obiekt był pierwszym
          odnowionym przez Towarzystwo Przyjaciół Mińska Mazowieckiego. Jego
          restaurację przeprowadzono, dzięki środkom finansowym zebranym w
          trakcie Społecznych Kwest Cmentarnych na Rzecz Ratowania Zabytkowych
          Nagrobków znajdujących się na cmentarzu parafialnym w Mińsku
          Mazowieckim. Zaangażowanie TPMM pozwoliło do 2020 roku uratować od
          zniszczenia około trzydzieści nagrobków posiadających cechy
          zabytkowości. Wracając do dziewiętnastowiecznych mogił znajdujących
          się na nadsrebrzańskiej nekropolii należy wspomnieć, że są tu
          pochowani powstańcy styczniowi oraz urzędnicy pracujący w
          administracji powiatu stanisławowskiego, później nowomińskiego. Możemy
          wśród nich wymienić takie osoby jak m.in. Leopold John, Karol
          Jurkowski, Stanisław Przegaliński i Jan Nepomucen Piekałkiewicz. W
          wojsku rosyjskim służyli natomiast pochowani w mieście nad Srebrną
          podporucznik Maurycy Rudnicki (słynny nagrobek – drzewo) oraz
          podpułkownik Onufry Suryn.
        </p>
        <p>
          Wrażenie na osobach odwiedzających cmentarz posiadają postawione w
          ostatniej dekadzie XIX wieku oraz na przełomie stuleci pomniki
          nagrobne kobiet. Uwagę zwracają nagrobki Wandy Walerii z Wasilewskich
          Bissen, Aleksandry z Dybowskich Wolskiej, Jadwigi z Dybowskich
          Wolskiej, Kazimiery z Rydzykowskich Wojewódzkiej, a w szczególności
          Józefiny Żełotbruchow z domu baronowej von Fitingoff-Schell. Ostatni z
          wymienionych, znajdujący się niedaleko bramy głównej cmentarza,
          przedstawia modlącą się Matkę Boską. Dziewiętnastowieczne nagrobki
          zlokalizowane na mińskomazowieckim cmentarzu swoją liczbą zdecydowanie
          ustępują tym, które pojawiły się na jego obszarze w następnym
          stuleciu. Dwudziesty wiek przyniósł pochowanie w tym miejscu wielu
          osób ważnych nie tylko dla dziejów miasta, ale i istotnych w skali
          ogólnopolskiej. W związku z tym przybywając na cmentarz parafialny w
          mieście nad Srebrną możemy natknąć się na nagrobki m.in. znanej
          neuropatolog Ewy Osetowskiej, prawnika i historyka prawa Bolesława
          Olszamowskiego i jego syna adiutanta Marszałka Józefa Piłsudskiego
          Janusza Łaszczyc – Olszamowskiego, kolarza Feliksa Rawskiego, malarza
          Juliana Ceglińskiego członka Organizacji Bojowej Polskiej Partii
          Socjalistycznej Zygmunta Kazikowskiego oraz przewodniczącego Rady
          Pomocy Żydom „Żegoty” Juliana Grobelnego. Na długiej liście osób
          zasłużonych stricte dla Mińska Mazowieckiego, których nagrobki można
          zobaczyć na nekropolii wskażmy doktora Jana Huberta, inżyniera
          Aureliusza Chróścielewskiego, prezesa oddziału Towarzystwa
          Gimnastycznego „Sokół” Wacława Kołpaka oraz poetę i malarza Krzysztofa
          Szczypiorskiego. Przy alei głównej znajdują się także miejsca
          spoczynku proboszczów kościoła p.w. Narodzenia Najświętszej Maryi
          Panny w Mińsku Mazowieckim. Do ważnych miejsc znajdujących się w tej
          części miasta należą ponadto kwatery żołnierzy poległych lub zmarłych
          z ran w trakcie kampanii wrześniowo-październikowej 1939 roku. Tuż
          obok nich zlokalizowane są nierzadko symboliczne nagrobki bohaterek i
          bohaterów konspiracyjnej walki z okupantem niemieckim. W gronie tym
          godna zauważenia jest tablica upamiętniająca postać komendanta Obwodu
          Armii Krajowej „Mewa”, „Jamnik, „Kamień” Ludwika Wolańskiego ps.
          „Lubicz”. Nieopodal niego można dostrzec pamiątkowy krzyż oraz
          popiersie ks. Jerzego Popiełuszki. W drugiej dekadzie lat 80 – tych XX
          wieku spotykali się tym miejscu lokalni członkowie opozycji
          antykomunistycznej. Cmentarz parafialny w Mińsku Mazowieckim stanowi
          jedno z najważniejszych miejsc związanych z dziejami miasta nad
          Srebrną. Odwiedzając go warto pamiętać o pochowanych na jego obszarze
          osobach zasłużonych dla historii nadsrebrzańskiego grodu.
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/minsk-mazowiecki/cmentarz-parafialny/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(
      filter: {
        relativePath: { regex: "/minsk-mazowiecki/cmentarz-parafialny/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default MinskMazowieckiCmentarzParafialny
